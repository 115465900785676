import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../layouts/index";

import Carousel from "react-bootstrap/lib/Carousel";

import Icon1 from "../images/icon/Icon1.svg";
import Icon2 from "../images/icon/Icon2.svg";
import Icon3 from "../images/icon/Icon3.svg";

const IndexPage = (props) => (
  <Layout {...props}>
    <main>
      <div class="jumbotron">
        <div class="container bgblur">
          <h3>Möhl-Areal in Köln-Dellbrück</h3>
          <p>
            <small>
              Aktuelle Projekt&shy;entwicklung der Möhl GmbH & Co. KG
            </small>
          </p>
        </div>
        <div className="container">
          <p className="center-block center">
            <a
              class="btn btn-primary btn-lg"
              href="https://isr-planung.de/download_offentlich/zweiphasiges-wettbewerbsverfahren-moehl-areal-mit-ideenteil-in-koeln-dellbrueck-ausstellung/"
              role="button"
            >
              Mehr Erfahren
            </a>
          </p>
        </div>
      </div>
      <h6>
        <small>
          Quelle und Urheber: Thomas Schüler Architekten und Stadtplaner
        </small>
      </h6>
      <p className="text-center no-top">
        <small>
          Geplant ist ein autoarmes Quartier mit hoher Aufenthaltsqualität. Das
          Möhl-Areal verknüpft nachhaltig Wohnen und Arbeiten.
        </small>
      </p>

      <section className="container arealsection">
        <div className="row section-margin-bottom">
          <div className="col-md-4">
            <p className="text-center">
              Das Planungsteam Thomas Schüler Architekten und Stadtplaner,
              Düsseldorf mit faktorgruen Landschaftsarchitekten aus Freiburg hat
              im Dezember 2021 das Wettbewerbsverfahren zur Gestaltung des
              Möhl-Areals in Köln-Dellbrück gewonnen.
            </p>
          </div>
          <div className="col-md-4">
            <p className="text-center">
              Die Firma Möhl als Eigentümerin und Bestandshalterin des Areals
              baut auf dem insgesamt 2,9 Hektar großen Gelände am{" "}
              <span style={{ whiteSpace: "nowrap" }}>S-Bahnhof</span> Dellbrück,
              nördlich der Bergisch Gladbacher Straße und östlich der
              Möhlstraße, bis zu 215 Wohnungen in unterschiedlichen
              Ausführungen. Entstehen soll auf dem heute überwiegend gewerblich
              genutzten Areal schrittweise ein Mischgebiet aus Mietwohnungsbau,
              Büros und kleineren Gewerbeflächen.
            </p>
          </div>
          <div className="col-md-4">
            <p className="text-center">
              Basierend auf dem „Kooperativen Baulandmodell“ wird sowohl
              freifinanzierter als auch öffentlich geförderter Wohnraum mit
              einer Quote von 70 zu 30 Prozent in jedem einzelnen Gebäude
              geschaffen.
              <br /> Nach Abschluss des B-Planverfahrens soll in der ersten Jahreshälfte 2026 mit dem Bau begonnen werden.
            </p>
          </div>
        </div>

        <img
          src="/samples/1011_Pers_1-2048x1417.jpg"
          className="img-responsive"
        />
        <h6>
          <small>
            Quelle und Urheber: Thomas Schüler Architekten und Stadtplaner
          </small>
        </h6>
        <br />
        <br />
      </section>

      <section className="container">
        <div className="blue-line" />
        <div className="row section-margin-bottom">
          <div className="col-sm-6 col-sm-push-6">
            <h1>Herzlich Willkommen bei der Firma Möhl</h1>
            <p>
              Seit der Firmengründung im Jahr 1856 steht der Name Möhl für
              Kompetenz und Flexibilität - und das bereits in der 5. Generation!{" "}
              <br />
              <br />
              Über die Jahre hat die Möhl GmbH & Co. KG immer wieder nach neuen
              Herausforderungen gesucht und das Unternehmen an die veränderten
              wirtschaftlichen Rahmenbedingungen angepasst:
              <br />
              <br />
              Als Fabrik für feuerfeste Erzeugnisse gegründet, wandelte sich der
              Familienbetrieb mit den Jahren so zu einem mittelständischen
              Unternehmen für Industrieofenbau und schließlich zu einer
              Verwaltung für die firmeneigenen Immobilien. <br />
              <br />
              Bis heute widmet sich die Möhl GmbH und Co. KG der Vermietung,
              Verwaltung und Projektierung dieser Immobilien und baut ihre
              Expertise kontinuierlich aus, um auch für die Zukunft gut
              aufgestellt zu sein.
            </p>
            <div className="blue-line" />
          </div>
          <div className="col-sm-6 col-sm-pull-6">
            <div className="img-startpage img-full-width">
              <Img alt="bg" fluid={props.data.moehl.childImageSharp.fluid} />
            </div>
          </div>
        </div>
        <div className="row text-center">
          <h4 className="startpage-center-text">Unsere Kompetenzen</h4>
        </div>
        <div className="row section-margin-bottom">
          <div className="col-md-4">
            <img src={Icon1} className="center-block featureIcon" />
            <h4 className="text-center">
              Verwaltung von
              <br />
              Wohnimmobilien
            </h4>
            <p className="text-center">
              Unsere firmeneigenen Wohnimmobilien in Köln werden von uns
              allumfassend verwaltet.
            </p>
          </div>
          <div className="col-md-4">
            <img src={Icon2} className="center-block featureIcon" />
            <h4 className="text-center">
              Verwaltung von
              <br />
              Gewerbeimmobilien
            </h4>
            <p className="text-center">
              Als kompetenter Verwalter unserer firmeneigenen Gewerbeobjekte in
              Köln bieten wir Ihnen die passenden Räumlichkeiten für Ihr
              Unternehmen.
            </p>
          </div>
          <div className="col-md-4">
            <img src={Icon3} className="center-block featureIcon" />
            <h4 className="text-center">
              Projektierung der
              <br />
              Bestandsimmobilien
            </h4>
            <p className="text-center">
              Unsere Bestandsimmobilien werden von uns fortlaufend projektiert
              und weiterentwickelt.
            </p>
          </div>
        </div>
      </section>
      <Carousel id="carousel-index">
        <Carousel.Item>
          <Img alt="bg" fluid={props.data.home0.childImageSharp.fluid} />
          <Carousel.Caption />
        </Carousel.Item>
        <Carousel.Item>
          <Img alt="bg" fluid={props.data.home1.childImageSharp.fluid} />
          <Carousel.Caption />
        </Carousel.Item>
        <Carousel.Item>
          <Img alt="bg" fluid={props.data.home2.childImageSharp.fluid} />
          <Carousel.Caption />
        </Carousel.Item>
        <Carousel.Item>
          <Img alt="bg" fluid={props.data.home3.childImageSharp.fluid} />
          <Carousel.Caption />
        </Carousel.Item>
        <Carousel.Item>
          <Img alt="bg" fluid={props.data.home4.childImageSharp.fluid} />
          <Carousel.Caption />
        </Carousel.Item>
        <Carousel.Item>
          <Img alt="bg" fluid={props.data.home5.childImageSharp.fluid} />
          <Carousel.Caption />
        </Carousel.Item>
        <Carousel.Item>
          <Img alt="bg" fluid={props.data.home6.childImageSharp.fluid} />
          <Carousel.Caption />
        </Carousel.Item>
      </Carousel>
    </main>
  </Layout>
);

export default IndexPage;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
  query {
    home0: file(relativePath: { eq: "home/home0.jpg" }) {
      ...fluidImage
    }
    home1: file(relativePath: { eq: "home/home1.jpg" }) {
      ...fluidImage
    }
    home2: file(relativePath: { eq: "home/home2.jpg" }) {
      ...fluidImage
    }
    home3: file(relativePath: { eq: "home/home3.jpg" }) {
      ...fluidImage
    }
    home4: file(relativePath: { eq: "home/home4.jpg" }) {
      ...fluidImage
    }
    home5: file(relativePath: { eq: "home/home5.jpg" }) {
      ...fluidImage
    }
    home6: file(relativePath: { eq: "home/home6.jpg" }) {
      ...fluidImage
    }
    moehl: file(relativePath: { eq: "moehldesk.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 520) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
